import { createFeatureFlag } from '../model';

const $$crowdinInContextFeatureFlag = createFeatureFlag(
  'FE_CHECKOUT_CROWDIN_IN_CONTEXT'
);

const $$isPLLangEnabledFeatureFlag = createFeatureFlag(
  'KUPAY_1936_FE_CHECKOUT_PL_LANGUAGE_ENABLED'
);

const $$isESLangEnabledFeatureFlag = createFeatureFlag(
  'KUPAY_1936_FE_CHECKOUT_ES_LANGUAGE_ENABLED'
);

const $$recaptchaFeatureFlag = createFeatureFlag('RECAPTCHA_ENABLED');

export {
  $$crowdinInContextFeatureFlag,
  $$isESLangEnabledFeatureFlag,
  $$isPLLangEnabledFeatureFlag,
  $$recaptchaFeatureFlag,
};
