/* eslint-disable effector/no-ambiguity-target */ // FIXME

import type { Effect, Event, Store } from 'effector';
import { combine } from 'effector';
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector';
import i18next from 'i18next';
import { and, equals, not, or } from 'patronum';

import { createDynamicTimer, listen } from '@kuna-pay/utils/effector';
import { createSyncSearch } from '@kuna-pay/utils/effector/search';
import { invariant } from '@kuna-pay/utils/invariant';
import { bridge } from '@kuna-pay/utils/misc';
import { objectEntries } from '@kuna-pay/utils/typescript';
import { notify } from '@kuna-pay/ui/notification';
import { ErrorMatcher } from '@kuna-pay/core/shared/api';

import { InvoiceStatus } from '@kuna-pay/accept-payment/generated/graphql';
import { executePublicInvoice } from '@kuna-pay/accept-payment/shared/api/generated/Invoice/request/executePublicInvoice';

import type {
  PaymentMethod,
  PaymentMethodOption,
  PublicInvoiceDetailsOutput,
} from '../api';
import { changeCurrentPaymentMethodFx, findPaymentMethodsFx } from '../api';
import { PaymentMethodAdapter } from '../page.lib';

const ChoosePaymentMethod = {
  statuses: new Set([
    InvoiceStatus.Created,
    InvoiceStatus.ConfirmationAwaiting,
    InvoiceStatus.LimitsOutOfRange,
  ]),

  createModel: (config: {
    $invoiceId: Store<string>;
    $invoiceStatus: Store<InvoiceStatus | null>;
    getInvoiceInfoFx: Effect<void, PublicInvoiceDetailsOutput>;
  }) => {
    //Implicit deps on same getInvoiceInfoFx to optimize api calls
    const refetchInvoiceInfoFx = config.getInvoiceInfoFx;

    const changePaymentMethodFx = attach({
      source: { id: config.$invoiceId },
      mapParams: (
        {
          paymentAsset,
          paymentMethodCode,
        }: { paymentAsset: string; paymentMethodCode: string },
        { id }
      ) => ({ id, paymentAsset, paymentMethodCode }),
      effect: changeCurrentPaymentMethodFx,
    });

    const getPaymentMethodsFx = attach({
      source: config.$invoiceId,
      mapParams: (_: void, invoiceId) => ({ id: invoiceId }),
      effect: findPaymentMethodsFx,
    });

    const proceedCheckoutFx = attach({
      source: { id: config.$invoiceId },
      effect: createEffect(
        executePublicInvoice({
          id: true,
        })
      ),
    });

    //commands
    const load = createEvent();
    const reset = createEvent();

    //events
    const submitted = createEvent();

    const $$invoicePaymentMethodExpireTimer = createDynamicTimer();

    const $$selectPaymentMethod = createSyncSelectModel<
      PaymentMethodOption,
      PaymentMethod
    >({
      getOptionsFx: getPaymentMethodsFx,

      match: (option, query) =>
        [option.asset, option.network]
          .filter(Boolean)
          .some((str) => str!.toLowerCase().includes(query.toLowerCase())),

      valueAdapter: (_, option) =>
        PaymentMethodAdapter.fromPaymentMethodOption(option),

      updateFilter: (prevValue, nextValue) => {
        if (prevValue === null || nextValue === null) return true;

        //shallowEqual
        return objectEntries(prevValue).some(
          ([key, value]) => nextValue[key] !== value
        );
      },
    });

    bridge(() => {
      sample({
        clock: load,
        target: $$selectPaymentMethod.load,
      });

      listen({
        clock: onlyInChoosePaymentInvoiceStatus(refetchInvoiceInfoFx.doneData),
        handler: async (invoice) => {
          $$selectPaymentMethod.init(
            PaymentMethodAdapter.fromPublicInvoiceOutput(invoice)
          );

          if (invoice.expireAt) {
            $$invoicePaymentMethodExpireTimer.init({
              endsAt: invoice.expireAt,
            });
          }
        },
      });
    });

    listen({
      clock: onlyInChoosePaymentInvoiceStatus($$selectPaymentMethod.changed),
      source: $$selectPaymentMethod.$value,
      handler: async (_, paymentMethod) => {
        if (!paymentMethod) return;

        try {
          await changePaymentMethodFx({
            paymentAsset: paymentMethod.asset,
            paymentMethodCode: paymentMethod.code,
          });
        } catch (e) {
          notify.warning(
            i18next.t(
              'pages.checkout.choose-payment-method.model.change-payment-method.failed',
              { replace: { code: paymentMethod.asset } }
            )
          );
        } finally {
          await refetchInvoiceInfoFx();
        }
      },
    });

    listen({
      name: '$$choose-payment-method.onSelectedPaymentMethodExpired',

      clock: onlyInChoosePaymentInvoiceStatus(
        $$invoicePaymentMethodExpireTimer.finally
      ),

      source: $$selectPaymentMethod.$value,

      handler: async (_, paymentMethod) => {
        invariant.error(paymentMethod, 'Payment method is not selected');

        try {
          await changePaymentMethodFx({
            paymentAsset: paymentMethod.asset,
            paymentMethodCode: paymentMethod.code,
          });

          /**
           * Shouldn't refetch invoice in finally block
           * because it will create infinite loop if the payment method is expired
           *
           * @see https://kunatech.atlassian.net/browse/KUPAY-1827
           */
          await refetchInvoiceInfoFx();
        } catch (e) {
          notify.warning(
            i18next.t(
              'pages.checkout.choose-payment-method.model.renew-payment-method.failed'
            )
          );
        }
      },
    });

    const $isOutOfRangeStatus = equals(
      config.$invoiceStatus,
      InvoiceStatus.LimitsOutOfRange
    );
    const $isInvalid = or(
      not($$selectPaymentMethod.$value),

      changePaymentMethodFx.pending,

      refetchInvoiceInfoFx.pending,

      not($$invoicePaymentMethodExpireTimer.$pending),

      $isOutOfRangeStatus
    );

    bridge(() => {
      sample({
        clock: onlyInChoosePaymentInvoiceStatus(submitted),
        filter: not($isInvalid),
        target: proceedCheckoutFx,
      });

      listen({
        clock: onlyInChoosePaymentInvoiceStatus(proceedCheckoutFx.fail),
        handler: ({ error }) => {
          if (
            ErrorMatcher.createErrorMatcher('PRE_REQUEST_OF_INVOICE_EXPIRED')(
              error
            )
          ) {
            notify.warning(
              i18next.t(
                'pages.checkout.choose-payment-method.model.proceed-checkout.failed.expired'
              )
            );

            return;
          }

          if (
            /**
             * "message": "An invoice can only be executed once after pre-request!",
             * "code": "UNABLE_TO_EXECUTE_INVOICE",
             */
            ErrorMatcher.createErrorMatcher('UNABLE_TO_EXECUTE_INVOICE')(error)
          ) {
            void refetchInvoiceInfoFx();

            return;
          }

          notify.warning(
            i18next.t(
              'pages.checkout.choose-payment-method.model.proceed-checkout.failed.unknown'
            )
          );
        },
      });

      sample({
        clock: onlyInChoosePaymentInvoiceStatus(proceedCheckoutFx.done),
        target: refetchInvoiceInfoFx,
      });
    });

    function onlyInChoosePaymentInvoiceStatus<EventPayload>(
      event: Event<EventPayload>
    ) {
      return sample({
        clock: event,
        filter: combine(config.$invoiceStatus, (status) =>
          ChoosePaymentMethod.statuses.has(status!)
        ),
      });
    }

    const $isNoCurrentSelectedPaymentMethod = or(
      equals(config.$invoiceStatus, InvoiceStatus.Created)
    );
    const $isSelectedPaymentMethodOutOfLimits = equals(
      config.$invoiceStatus,
      InvoiceStatus.LimitsOutOfRange
    );

    const $loadingInitialRates = and(
      or(changePaymentMethodFx.pending, refetchInvoiceInfoFx.pending),
      or($isNoCurrentSelectedPaymentMethod, $isSelectedPaymentMethodOutOfLimits)
    );

    const $changingPaymentMethod = and(
      or(changePaymentMethodFx.pending, refetchInvoiceInfoFx.pending),
      equals(config.$invoiceStatus, InvoiceStatus.ConfirmationAwaiting)
    );

    return {
      load,
      reset,

      $$ui: {
        $$invoicePaymentMethodExpireTimer,
        $$selectPaymentMethod: $$selectPaymentMethod.$$ui,

        $isInvalid,

        submitted,

        $submitting: proceedCheckoutFx.pending,

        $isOutOfRangeStatus,

        $loadingInitialRates,
        $changingPaymentMethod,
      },

      __: {
        changePaymentMethodFx,
        getPaymentMethodsFx,
        refetchInvoiceInfoFx,
        proceedCheckoutFx,
      },
    };
  },
};

//TODO: extract to lib
function createSyncSelectModel<Option, Value>(config: {
  getOptionsFx: Effect<void, Option[]>;

  match: (option: Option, query: string) => boolean;

  valueAdapter: (prevValue: Value | null, option: Option) => Value | null;

  updateFilter: (prevValue: Value | null, nextValue: Value | null) => boolean;
}) {
  const getPaymentMethodsFx = attach({ effect: config.getOptionsFx });

  //commands
  const init = createEvent<Value | null>();

  //events
  const buttonClicked = createEvent();
  const searchItemClicked = createEvent<Option>();
  const goBackClicked = createEvent();

  //stores
  const $isOpen = createStore(false);
  const $value = createStore<Value | null>(null, {
    updateFilter: config.updateFilter,
  });

  //models
  const $$search = createSyncSearch({
    getOptionsFx: getPaymentMethodsFx,

    match: config.match,
  });

  bridge(() => {
    $value.on(init, (_, value) => value);
  });

  bridge(() => {
    $isOpen.on(buttonClicked, () => true);
  });

  bridge(() => {
    $isOpen.on(searchItemClicked, () => false);

    $value.on(searchItemClicked, config.valueAdapter);
  });

  bridge(() => {
    sample({
      clock: goBackClicked,
      target: $$search.clear,
    });

    $isOpen.on(goBackClicked, () => false);
  });

  return {
    load: $$search.load,
    init,

    $value,

    changed: searchItemClicked,

    $$ui: {
      $$search: $$search.$$ui,

      $isOpen,

      $value,

      buttonClicked,
      searchItemClicked,
      goBackClicked,
    },
  };
}

export { ChoosePaymentMethod };
