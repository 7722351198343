import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CopyButton } from '@kuna-pay/ui/ui/button';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { useAssetFormat } from '@kuna-pay/core/entities/asset';

import { CheckoutPageModel } from '@kuna-pay/accept-payment/pages/checkout/page.model';

import styles from './invoice-amount.module.scss';

type InvoiceAmountProps = {
  className?: string;
};

const InvoiceAmount: FC<InvoiceAmountProps> = ({ className }) => {
  const $$model = CheckoutPageModel.useModel();

  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.checkout.proceed-payment.invoice-amount',
  });
  const AssetFormat = useAssetFormat();

  // Always have this fields in PaymentAwaiting status
  const invoice = useUnit($$model.$invoice)!;
  const { paymentAmount, invoiceAmount, InvoiceAsset, PaymentAsset } = invoice;

  return (
    <div className={clsx(styles.root, className)}>
      <Typography className='text-black600' variant='subtitle5'>
        {t('title')}:
      </Typography>

      <CopyButton
        classes={{ button: styles.copyButton }}
        value={paymentAmount}
        customMessage='Amount copied successfully'
        size='lg'
      >
        <Typography variant='h6'>
          {AssetFormat.formatAmount(paymentAmount, PaymentAsset!)}
        </Typography>
      </CopyButton>

      <Typography className={styles.estimatedAmount} variant='body3'>
        ≈{AssetFormat.formatAmount(invoiceAmount, InvoiceAsset)}
      </Typography>
    </div>
  );
};

export { InvoiceAmount };
