import type { Store } from 'effector';
import { createEvent, createStore, sample } from 'effector';
import i18next from 'i18next';
import { not } from 'patronum';
import { object, string } from 'yup';

import {
  createEffectWithLastParams,
  getState,
  listen,
  modelFactory,
  UNIT_SHAPE,
} from '@kuna-pay/utils/effector';
import { atom, bridge } from '@kuna-pay/utils/misc';
import { notify } from '@kuna-pay/ui/notification';
import { createForm } from '@kuna-pay/form';
import { TimeoutedResend } from '@kuna-pay/core/features/auth/abstract/timeouted-resend';
import { ErrorMatcher } from '@kuna-pay/core/shared/api';
import { RecaptchaRabacErrorMatcher } from '@kuna-pay/core/shared/recaptcha';

import { $$analytics } from '@kuna-pay/accept-payment/shared/analytics';
import { sendInvoiceReceiptToBuyer } from '@kuna-pay/accept-payment/shared/api/generated/Invoice/request/sendInvoiceReceiptToBuyer';
import { $$recaptcha } from '@kuna-pay/accept-payment/shared/recaptcha';

type SendReceiptModelConfig = {
  $invoiceId: Store<string>;
};

const SendReceiptModel = modelFactory(
  ({ $invoiceId }: SendReceiptModelConfig) => {
    const cancelled = createEvent();

    const $$sendReceiptFx = createEffectWithLastParams(
      sendInvoiceReceiptToBuyer({ success: true })
    );

    const $$form = createForm<{ email: string }>({
      $disabled: $$sendReceiptFx.fx.pending,

      initialValues: { email: '' },

      schema: object({
        email: string()
          .email()
          .required()
          .label(
            i18next.t('pages.checkout.send-receipt.form.email.errors.label')
          ),
      }),
    });

    const $$resend = TimeoutedResend.factory.createModel({
      resendFx: $$sendReceiptFx.retryFx,
      timeoutInSeconds: 60,

      persist: {
        name: 'send-receipt-timer',
      },
    });

    const $$view = atom(() => {
      const open = createEvent();
      const close = createEvent();
      const $isOpen = createStore(false);

      $isOpen.on(open, () => true);
      $isOpen.on(close, () => false);

      return {
        open,
        close,
        $isOpen,
      };
    });

    listen({
      clock: $$form.submitted,
      source: $invoiceId,
      handler: async ({ email }, invoiceId) => {
        try {
          await $$recaptcha.ensureRecaptchaFx();

          await $$sendReceiptFx.fx({ id: invoiceId, email });
        } catch (e) {
          if (RecaptchaRabacErrorMatcher.isInvalidRecaptchaError(e)) {
            const isValidConfig = await getState($$recaptcha.$isEnabled);

            notify.warning(
              i18next.t(
                isValidConfig
                  ? 'shared.recaptcha.failed'
                  : 'shared.recaptcha.misconfiguration-error',
                { ns: 'core' }
              )
            );

            return;
          }
        }
      },
    });

    bridge(() => {
      listen({
        clock: $$sendReceiptFx.fx.done,
        handler: ({ params }) => {
          $$analytics.logEvent({
            event: 'Invoice Receipt Sent',
            properties: {
              invoiceId: params.id,
            },
          });

          notify.success(
            i18next.t('pages.checkout.send-receipt.model.sent.success')
          );
          $$view.close();
          $$resend.startTimer();
        },
      });

      listen({
        clock: $$sendReceiptFx.fx.failData,
        handler: (error) => {
          if (ErrorMatcher.createErrorMatcher('RATE_LIMIT')(error)) {
            notify.warning(
              i18next.t(
                'pages.checkout.send-receipt.model.sent.failed.rate-limit'
              )
            );

            return;
          }

          notify.warning(
            i18next.t('pages.checkout.send-receipt.model.sent.failed.unknown')
          );
        },
      });
    });

    bridge(() => {
      /**
       * If form is dirty, then reset it
       * Otherwise, return to the previous page
       */
      sample({
        clock: cancelled,
        filter: not($$form.$dirty),
        target: $$view.close,
      });
    });

    bridge(() => {
      sample({
        clock: $$view.close,
        target: $$form.reinit,
      });
    });

    return {
      $$ui: {
        isOpen: $$view.$isOpen,
        open: $$view.open,
        close: $$view.close,

        cancelled,

        $$form,
        $$resend,
      },

      [UNIT_SHAPE]: () => ({
        isOpen: $$view.$isOpen,
        open: $$view.open,
        close: $$view.close,
      }),
    };
  }
);

export { SendReceiptModel };
