import clsx from 'clsx';
import type { FC, HTMLAttributes } from 'react';

import { WarningIcon } from '@kuna-pay/ui/icons';
import { Typography } from '@kuna-pay/ui/ui/typography';

import styles from './warning.module.scss';

type WarningProps = Omit<HTMLAttributes<HTMLDivElement>, 'title'> & {
  title: string;
};

const Warning: FC<WarningProps> = ({
  title,
  children,
  className,
  ...props
}) => (
  <div className={clsx(styles.root, className)} {...props}>
    <div className={styles.iconContainer}>
      <WarningIcon className='text-yellow400' />
    </div>

    <div className={styles.content}>
      <Typography variant='subtitle6'>{title}</Typography>

      <Typography className={styles.description} variant='numbers2'>
        {children}
      </Typography>
    </div>
  </div>
);

export { Warning };
export type { WarningProps };
