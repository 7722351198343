import { memo } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { RouterErrorBoundary } from '@kuna-pay/ui/error-boundary';

import { CheckoutPage } from '@kuna-pay/accept-payment/pages/checkout';
import { NotFoundPage } from '@kuna-pay/accept-payment/pages/not-found';

const router = createBrowserRouter([
  {
    path: '/:id',
    Component: CheckoutPage,
    ErrorBoundary: RouterErrorBoundary,
  },
  {
    path: '*',
    Component: NotFoundPage,
    ErrorBoundary: RouterErrorBoundary,
  },
]);

const Pages = memo(() => <RouterProvider router={router} />);

export { Pages };
