import { TransferType as PublicTransferType } from '@kuna-pay/core/generated/public/graphql';

const transactionTypeToI18n = {
  [PublicTransferType.InvoiceDeposit]:
    'entities.transaction.ui.type.invoice-deposit',
  [PublicTransferType.Deposit]: 'entities.transaction.ui.type.deposit',
  [PublicTransferType.Withdraw]: 'entities.transaction.ui.type.withdraw',
  [PublicTransferType.Refund]: '',
  [PublicTransferType.PayoutWithdraw]: 'entities.transaction.ui.type.payout',
  [PublicTransferType.Convert]: 'entities.transaction.ui.type.convert',
};

export { transactionTypeToI18n };
