import { Slot } from '@radix-ui/react-slot';
import clsx from 'clsx';
import type {
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  SVGAttributes,
} from 'react';
import React from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type { PaperProps } from '@kuna-pay/ui/ui/paper';
import { Paper } from '@kuna-pay/ui/ui/paper';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';

import styles from './item.module.scss';

type DecriptionDetailsItemProps = PropsWithChildren & {
  icon: ReactNode;
  title: ReactNode;
};

type DescriptionDetailsRootProps = PaperProps & {
  variant?: 'filled' | 'outlined';
};

const DescriptionDetailsItem = createCompoundComponent(
  ({ Root, Title, Content, Description, Icon }) =>
    ({ title, icon, children }: DecriptionDetailsItemProps) =>
      (
        <Root>
          <Icon>{icon}</Icon>

          <Content>
            <Title>{title}</Title>

            <Description>{children}</Description>
          </Content>
        </Root>
      ),
  {
    Root: ({
      className,
      variant = 'filled',
      ...props
    }: DescriptionDetailsRootProps) => (
      <Paper
        className={clsx(
          styles.container,
          {
            [styles.outlined]: variant === 'outlined',
            [styles.filled]: variant === 'filled',
          },
          className
        )}
        rounded='lg'
        {...props}
      />
    ),

    Title: ({ className, ...props }: Partial<TypographyProps>) => (
      <Typography
        className={clsx(styles.title, className)}
        variant='numbers1'
        {...props}
      />
    ),

    Content: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.content, className)} {...props} />
    ),

    Description: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.valueContainer, className)} {...props} />
    ),

    Icon: (props: SVGAttributes<HTMLOrSVGElement>) => (
      <Slot fontSize={24} {...props} />
    ),
  }
);

export { DescriptionDetailsItem };
