import clsx from 'clsx';
import { useUnit } from 'effector-react';
import { useParams } from 'react-router-dom';

import { pageView } from '@kuna-pay/utils/effector/factorio';
import { Skeleton } from '@kuna-pay/ui/ui/skeleton';
import { useTypedGate } from '@kuna-pay/core/shared/router';

import { ChangeLanguage } from '@kuna-pay/accept-payment/features/change-language';
import { InvoiceStatus } from '@kuna-pay/accept-payment/generated/graphql';
import { LogEventOnMount } from '@kuna-pay/accept-payment/shared/analytics';
import { Footer } from '@kuna-pay/accept-payment/shared/ui/footer';
import { NotFoundTemplate } from '@kuna-pay/accept-payment/shared/ui/not-found-template';
import { MainLayout } from '@kuna-pay/accept-payment/widgets/layouts/main';

import { ChoosePaymentMethod } from './model';
import { CheckoutPageModel } from './page.model';
import {
  ChoosePaymentMethodView,
  CollectBuyerInfo,
  OrderDetailsArrested,
  OrderDetailsCurrencyMismatch,
  OrderDetailsDeactivated,
  OrderDetailsPaid,
  OrderDetailsPartiallyPaid,
  OrderDetailsSuspended,
  OrderDetailsTimeout,
  ProceedPaymentView,
} from './ui';
import styles from './page.module.scss';

const CheckoutPage = pageView(CheckoutPageModel, () => {
  const $$model = CheckoutPageModel.useModel();
  const params = useParams<{ id: string }>();
  useTypedGate({ Gate: $$model.PageGate, props: { id: params.id! } });

  const [invoice, isLoaded, status] = useUnit([
    $$model.$invoice,
    $$model.$isLoaded,
    $$model.$status,
  ]);

  if (!isLoaded) {
    return (
      <MainLayout
        classes={{
          root: styles.layout,
          content: styles.layoutContent,
          footer: styles.layoutFooter,
        }}
      >
        <Skeleton containerClassName={styles.loading} flex fullWidth />
      </MainLayout>
    );
  }

  if (status === InvoiceStatus.Created) {
    const invoiceHasQuestionnaire = !!invoice?.questionnaire;
    const invoiceQuestionnaireIsNotFilled =
      !invoice?.questionnaire?.Details?.isFilled;
    const shouldShowInvoiceQuestionnaire =
      invoiceHasQuestionnaire && invoiceQuestionnaireIsNotFilled;

    if (shouldShowInvoiceQuestionnaire) {
      return (
        <>
          <LogEventOnMount
            key={`${params.id}.${status}`}
            event='Checkout Viewed'
            properties={{ id: params.id, status: status }}
          />

          <LogEventOnMount
            key={`${params.id}.questionnaire`}
            event='Questionnaire Viewed'
            properties={{ id: params.id! }}
          />

          <MainLayout
            classes={{
              root: styles.layout,
              content: styles.layoutContent,
              footer: styles.layoutFooter,
            }}
          >
            <CollectBuyerInfo className={styles.page}>
              <Footer className={styles.footer}>
                <ChangeLanguage />
              </Footer>
            </CollectBuyerInfo>
          </MainLayout>
        </>
      );
    }
  }

  if (ChoosePaymentMethod.statuses.has(status!)) {
    return (
      <>
        <LogEventOnMount
          key={`${params.id}.${status}`}
          event='Checkout Viewed'
          properties={{ id: params.id, status: status }}
        />

        <MainLayout
          classes={{
            root: styles.layout,
            content: styles.layoutContent,
            footer: styles.layoutFooter,
          }}
        >
          <ChoosePaymentMethodView className={styles.page}>
            <Footer className={styles.footer}>
              <ChangeLanguage />
            </Footer>
          </ChoosePaymentMethodView>
        </MainLayout>
      </>
    );
  }

  if (status === InvoiceStatus.PaymentAwaiting) {
    return (
      <>
        <LogEventOnMount
          key={`${params.id}.${status}`}
          event='Checkout Viewed'
          properties={{ id: params.id, status: status }}
        />

        <MainLayout
          classes={{
            root: styles.layout,
            content: styles.layoutContent,
            footer: styles.layoutFooter,
          }}
        >
          <ProceedPaymentView className={styles.page}>
            <Footer className={styles.footer} />
          </ProceedPaymentView>
        </MainLayout>
      </>
    );
  }

  if (status === InvoiceStatus.Paid) {
    return (
      <>
        <LogEventOnMount
          key={`${params.id}.${status}`}
          event='Checkout Viewed'
          properties={{ id: params.id, status: status }}
        />

        <MainLayout
          classes={{
            root: clsx(styles.layout, styles.pt0, styles.flexGrow),
            content: styles.layoutContent,
            footer: styles.layoutFooter,
          }}
        >
          <OrderDetailsPaid className={styles.page}>
            <Footer className={styles.footer}>
              <ChangeLanguage />
            </Footer>
          </OrderDetailsPaid>
        </MainLayout>
      </>
    );
  }

  if (status === InvoiceStatus.PartiallyPaid) {
    return (
      <>
        <LogEventOnMount
          key={`${params.id}.${status}`}
          event='Checkout Viewed'
          properties={{ id: params.id, status: status }}
        />

        <MainLayout
          classes={{
            root: clsx(styles.layout, styles.pt0, styles.flexGrow),
            content: styles.layoutContent,
            footer: styles.layoutFooter,
          }}
        >
          <OrderDetailsPartiallyPaid className={styles.page}>
            <Footer className={styles.footer}>
              <ChangeLanguage />
            </Footer>
          </OrderDetailsPartiallyPaid>
        </MainLayout>
      </>
    );
  }

  if (status === InvoiceStatus.CurrencyMismatch) {
    return (
      <>
        <LogEventOnMount
          key={`${params.id}.${status}`}
          event='Checkout Viewed'
          properties={{ id: params.id, status: status }}
        />

        <MainLayout
          classes={{
            root: clsx(styles.layout, styles.pt0, styles.flexGrow),
            content: styles.layoutContent,
            footer: styles.layoutFooter,
          }}
        >
          <OrderDetailsCurrencyMismatch className={styles.page}>
            <Footer className={styles.footer}>
              <ChangeLanguage />
            </Footer>
          </OrderDetailsCurrencyMismatch>
        </MainLayout>
      </>
    );
  }

  if (status === InvoiceStatus.Suspended) {
    return (
      <>
        <LogEventOnMount
          key={`${params.id}.${status}`}
          event='Checkout Viewed'
          properties={{ id: params.id, status: status }}
        />

        <MainLayout
          classes={{
            root: clsx(styles.layout, styles.pt0, styles.flexGrow),
            content: styles.layoutContent,
            footer: styles.layoutFooter,
          }}
        >
          <OrderDetailsSuspended className={styles.page}>
            <Footer className={styles.footer}>
              <ChangeLanguage />
            </Footer>
          </OrderDetailsSuspended>
        </MainLayout>
      </>
    );
  }

  if (status === InvoiceStatus.Arrested) {
    return (
      <>
        <LogEventOnMount
          key={`${params.id}.${status}`}
          event='Checkout Viewed'
          properties={{ id: params.id, status: status }}
        />

        <MainLayout
          classes={{
            root: clsx(styles.layout, styles.pt0, styles.flexGrow),
            content: styles.layoutContent,
            footer: styles.layoutFooter,
          }}
        >
          <OrderDetailsArrested className={styles.page}>
            <Footer className={styles.footer}>
              <ChangeLanguage />
            </Footer>
          </OrderDetailsArrested>
        </MainLayout>
      </>
    );
  }

  if (status === InvoiceStatus.Timeout) {
    return (
      <>
        <LogEventOnMount
          key={`${params.id}.${status}`}
          event='Checkout Viewed'
          properties={{ id: params.id, status: status }}
        />

        <MainLayout
          classes={{
            root: clsx(styles.layout, styles.pt0, styles.flexGrow),
            content: styles.layoutContent,
            footer: styles.layoutFooter,
          }}
        >
          <OrderDetailsTimeout className={styles.page}>
            <Footer className={styles.footer}>
              <ChangeLanguage />
            </Footer>
          </OrderDetailsTimeout>
        </MainLayout>
      </>
    );
  }

  if (status === InvoiceStatus.Deactivated) {
    return (
      <>
        <LogEventOnMount
          key={`${params.id}.${status}`}
          event='Checkout Viewed'
          properties={{ id: params.id, status: status }}
        />

        <MainLayout
          classes={{
            root: clsx(styles.layout, styles.pt0, styles.flexGrow),
            content: styles.layoutContent,
            footer: styles.layoutFooter,
          }}
        >
          <OrderDetailsDeactivated className={styles.page}>
            <Footer className={styles.footer}>
              <ChangeLanguage />
            </Footer>
          </OrderDetailsDeactivated>
        </MainLayout>
      </>
    );
  }

  return (
    <>
      <LogEventOnMount
        key={`${params.id}.${status}`}
        event='Checkout Viewed'
        properties={{ id: params.id, status: status }}
      />

      <MainLayout
        classes={{
          root: styles.layout,
          content: styles.layoutContent,
          footer: styles.layoutFooter,
        }}
      >
        <NotFoundTemplate />
      </MainLayout>
    </>
  );
});

CheckoutPage.displayName = 'CheckoutPage';

export default CheckoutPage;
