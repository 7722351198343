import clsx from 'clsx';
import type { FC } from 'react';

import { NotFoundTemplate as CoreNotFoundTemplate } from '@kuna-pay/core/shared/ui/not-found-template';

import { Footer } from '../footer';
import styles from './not-found-template.module.scss';

const NotFoundTemplate: FC = () => (
  <CoreNotFoundTemplate>
    <Footer className={clsx(styles.footer, styles.notFoundFooter)} />
  </CoreNotFoundTemplate>
);

NotFoundTemplate.displayName = 'NotFoundTemplate';

export { NotFoundTemplate };
