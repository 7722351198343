import clsx from 'clsx';
import { useUnit } from 'effector-react';
import { QRCodeSVG } from 'qrcode.react';
import type { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { InfoIcon, WarningOutlineIcon } from '@kuna-pay/ui/icons';
import { CopyButton } from '@kuna-pay/ui/ui/button';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { useAssetFormat } from '@kuna-pay/core/entities/asset';
import type {
  Maybe,
  PublicProductOutput,
} from '@kuna-pay/core/generated/public/graphql';

import type { NonAuthDepositAddressOutput } from '@kuna-pay/accept-payment/generated/graphql';
import { InvoiceAmount } from '@kuna-pay/accept-payment/pages/checkout/ui/proceed-payment/invoice-amount';
import { Timer } from '@kuna-pay/accept-payment/shared/ui/timer';
import { Warning } from '@kuna-pay/accept-payment/shared/ui/warning';

import { CheckoutPageModel } from '../../../page.model';
import styles from './invoice-address.module.scss';

type InvoiceAddressProps = {
  className?: string;
};

const InvoiceAddress: FC<InvoiceAddressProps> = ({ className }) => {
  const $$model = CheckoutPageModel.useModel();

  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.checkout.proceed-payment.invoice-address',
  });

  const AssetFormat = useAssetFormat();

  const invoice = useUnit($$model.$invoice)!;

  //Note: always have this field in PaymentAwaiting status
  const { address, memo } = invoice.Address! as NonAuthDepositAddressOutput;

  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.rect}>
        <InvoiceAmount className={styles.invoiceAmount} />

        <Typography className={styles.qrCta} variant='subtitle5'>
          <Trans
            t={t}
            i18nKey='cta'
            components={{ body3: <Typography variant='body3' /> }}
          />
        </Typography>

        <div className={styles.qr}>
          <QRCodeSVG
            size={150}
            value={address}
            imageSettings={{
              src: invoice.Company.avatar ?? '/kuna-qr-default-logo.jpg',
              height: 45,
              width: 45,
              excavate: false,
            }}
          />
        </div>

        <TimeRemaining />

        <div className={styles.networkFeeWarning}>
          <WarningOutlineIcon />

          <Typography variant='numberAdmin2'>
            <Trans
              t={t}
              i18nKey='warnings.network-fee'
              values={{
                network: invoice.PaymentMethod?.network,
              }}
              components={{
                s4: <Typography variant='subtitle4' />,
              }}
            />
          </Typography>
        </div>

        {isMinDepositAmountWarningCanBeApplied(invoice.Product) && (
          <div className={styles.minDepositWarning}>
            <InfoIcon />

            <Typography variant='numberAdmin2'>
              <Trans
                t={t}
                i18nKey='warnings.min-deposit'
                values={{
                  amount: AssetFormat.formatAmount(
                    invoice.Product.minAmount,
                    invoice.PaymentAsset
                  ),
                }}
                components={{
                  numberAdmin3: <Typography variant='numberAdmin3' />,
                }}
              />
            </Typography>
          </div>
        )}

        <div className={styles.details}>
          <div className={styles.detailsRow}>
            <Typography className={styles.detailsRowLabel} variant='numbers1'>
              {t('wallet.label')}
            </Typography>

            <CopyButton
              classes={{
                root: clsx(styles.detailsRowValueContainer, styles.address),
              }}
              value={address}
              customMessage={t('wallet.copy.message')}
              size='lg'
            >
              <Typography variant='subtitle5'>{address}</Typography>
            </CopyButton>
          </div>

          {!!memo && (
            <div className={styles.detailsRow}>
              <Typography className={styles.detailsRowLabel} variant='numbers1'>
                {t('memo.label')}
              </Typography>

              <CopyButton
                classes={{
                  root: styles.detailsRowValueContainer,
                  button: styles.copyButton,
                }}
                value={memo}
                customMessage={t('memo.copy.message')}
                size='lg'
              >
                <Typography
                  className={styles.detailsRowValue}
                  variant='subtitle5'
                  nowrap
                >
                  {memo}
                </Typography>
              </CopyButton>
            </div>
          )}
        </div>
      </div>

      {!!memo && (
        <Warning className={styles.memo} title={t('memo.warning.title')}>
          {t('memo.warning.description')}
        </Warning>
      )}
    </div>
  );
};

const TimeRemaining = () => {
  const $$model = CheckoutPageModel.useModel();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.checkout.proceed-payment.time-remaining',
  });
  const timeLeft = useUnit($$model.$$paymentAwaiting.$$timer.$timeLeft);

  const isNumber = typeof timeLeft === 'number';

  if (!isNumber) {
    return null;
  }

  return (
    <Timer
      className={styles.timer}
      title={t('title')}
      timeInSeconds={timeLeft}
    />
  );
};

function isMinDepositAmountWarningCanBeApplied(
  Product?: Maybe<PublicProductOutput>
): Product is PublicProductOutput & { minAmount: string } {
  return !!Product?.minAmount && Product.minAmount !== '0';
}

export { InvoiceAddress };
